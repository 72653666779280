/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import './src/scss/theme.scss'
import { GlobalStoreProvider } from "./src/context/GlobalStoreContext"
import PropTypes from 'prop-types'

export const wrapRootElement = ({ element }) => <>
    <GlobalStoreProvider>
        {element}
    </GlobalStoreProvider>
</>

wrapRootElement.propTypes = {
    element: PropTypes.any
}
