import axiosETagCache from './axiosEtagCache'

// rest client setup...
const clientConfig = {
    baseURL: '/api/v1',
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 15000 // 15 seconds
}

// // intercept 401 Unauthorised, and redirect to login...
// client.interceptors.response.use(response => response, error => {
//     const { response: { status, config } = {} } = error // , request
//     // we call verifyIsLoggedIn onload just to check if a session exists,
//     // don't redirect to login page unless accessing a PrivateRoute.
//     if (status === 401 && config.url !== '/api/auth/verifyIsLoggedIn') {
//         console.log('intercepted 401 - redirecting to login')
//         navTo.login()
//     }
//     throw error
// })

export default axiosETagCache(clientConfig)
