// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-app-dashboard-js": () => import("../src/pages/app/dashboard.js" /* webpackChunkName: "component---src-pages-app-dashboard-js" */),
  "component---src-pages-app-friends-js": () => import("../src/pages/app/friends.js" /* webpackChunkName: "component---src-pages-app-friends-js" */),
  "component---src-pages-app-login-js": () => import("../src/pages/app/login.js" /* webpackChunkName: "component---src-pages-app-login-js" */),
  "component---src-pages-app-profile-js": () => import("../src/pages/app/profile.js" /* webpackChunkName: "component---src-pages-app-profile-js" */),
  "component---src-pages-app-subscriptions-js": () => import("../src/pages/app/subscriptions.js" /* webpackChunkName: "component---src-pages-app-subscriptions-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intro-js": () => import("../src/pages/intro.js" /* webpackChunkName: "component---src-pages-intro-js" */),
  "component---src-pages-kitchen-sink-js": () => import("../src/pages/kitchenSink.js" /* webpackChunkName: "component---src-pages-kitchen-sink-js" */),
  "component---src-pages-p-js": () => import("../src/pages/p.js" /* webpackChunkName: "component---src-pages-p-js" */),
  "component---src-pages-pricing-js": () => import("../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-scratchpad-js": () => import("../src/pages/scratchpad.js" /* webpackChunkName: "component---src-pages-scratchpad-js" */)
}

