import client from './RestClient'

/**
 * Library for accessing the backend functions.
 */


export const getStore = uid => client.get('/store', { params: { uid } })

export const setStore = (uid, store) => client.put('/store', { uid, store })

export const getStatus = () => client.get('/status')

export const getFriends = uid => client.get('/friends', { params: { uid } })

export const acceptFriend = (uid, friendId) => client.post('/friends/accept', { uid, friendId })

export const addFriend = (uid, friends) => client.post('/addfriend', { uid, friends })

export const searchUser = name => client.get('/users', { params: { name } })

export default {
    getStatus,
    getStore,
    setStore,
    getFriends,
    acceptFriend,
    addFriend,
    searchUser
}