import * as firebase from "firebase/app"
// Add the Firebase services that you want to use
import "firebase/auth"

export const API_URL = process.env.NODE_ENV === 'production'
  ? 'https://subclub.com.au/'
  : 'https://27b7add8.ngrok.io'


export const firebaseConfig = {
  apiKey: "AIzaSyDA8w8eKTWh-hR88yF_g3QPMd_scvTsDAY",
  authDomain: "subzone-prod.firebaseapp.com",
  databaseURL: "https://subzone-prod.firebaseio.com",
  projectId: "subzone-prod",
  storageBucket: "subzone-prod.appspot.com",
  messagingSenderId: "613693057992",
  appId: "1:613693057992:web:745924443a11a608a7aefd"
}

class Firebase {
  constructor() {
    // gatsby issue with build time https://github.com/gatsbyjs/gatsby/issues/6386
    if (typeof window !== 'undefined') {
      firebase.initializeApp(firebaseConfig)
      this.auth = firebase.auth
    }
  }
}

export default new Firebase()