// Credit (Influenced): https://github.com/raulanatol/axios-etag-cache

import axios from 'axios'

// setup a dumb cache...
const DumbCache = {}
DumbCache.get = (key) => DumbCache[key]
DumbCache.set = (key, value) => {
    DumbCache[key] = value
    console.debug('DumbCache size', Object.keys(DumbCache).length)
}
DumbCache.reset = () => { for (let member in DumbCache) delete DumbCache[member] }

function isCacheableMethod(config) {
    return ~['GET', 'HEAD'].indexOf(config.method.toUpperCase())
}

function getUUIDByAxiosConfig(config) {
    return config.url
}

function getCacheByAxiosConfig(config) {
    return DumbCache.get(getUUIDByAxiosConfig(config))
}

function requestInterceptor(config) {
    if (isCacheableMethod(config)) {
        const uuid = getUUIDByAxiosConfig(config)
        const lastCachedResult = DumbCache.get(uuid)
        if (lastCachedResult) {
            config.headers = { ...config.headers, 'If-None-Match': lastCachedResult.etag }
        }
    }
    return config
}

function responseInterceptor(response) {
    if (isCacheableMethod(response.config)) {
        const responseETAG = response.headers.etag
        if (responseETAG) {
            DumbCache.set(getUUIDByAxiosConfig(response.config), { etag: responseETAG, value: response.data })
        }
    }
    return response
}

function responseErrorInterceptor(error) {
    if (error.response.status === 304) {
        const getCachedResult = getCacheByAxiosConfig(error.response.config)
        if (!getCachedResult) {
            return Promise.reject(error)
        }
        const newResponse = error.response
        newResponse.status = 304
        newResponse.data = getCachedResult.value
        return Promise.resolve(newResponse)
    }
    return Promise.reject(error)
}

export function resetCache() {
    DumbCache.reset()
}

export default function axiosEtagCache(config) {
    const instance = axios.create(config)
    instance.interceptors.request.use(requestInterceptor)
    instance.interceptors.response.use(responseInterceptor, responseErrorInterceptor)

    return instance
}