
import deepEqual from 'deep-equal'

export const parsePath = path => path
    .replace(/\[|\]\.?/g, '.')
    .split('.')
    .filter(s => s)

export const get = (obj, path) => parsePath(path).reduce((acc, val) => acc && acc[val], obj)

export const put = (obj, path, value) => {
    const paths = parsePath(path)
    const lastPath = paths.splice(-1)
    const parent = paths.reduce((acc, path) => acc[path] || (acc[path] = {}), obj)
    if (!deepEqual(parent[lastPath], value)) {
        parent[lastPath] = value
        return true
    } else {
        return false
    }
}

// todo @sam - export const push = 

export const clear = (obj, path) => {
    const paths = parsePath(path)
    const lastPath = paths.splice(-1)
    const parent = paths.reduce((acc, path) => acc[path] || (acc[path] = {}), obj)
    delete parent[lastPath]
}