import cc from 'currency-codes'
import moment from 'moment-timezone'

const longDate = Intl.DateTimeFormat([], { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })

export const formatLongDate = longDate.format

// new Date().getTimezoneOffset() // -660

/**
 * Gets the default language e.g. 'en-GB' (Alternative: navigator.language / navigator.languages).
 */
export const getDefaultLanguage = () => Intl.NumberFormat().resolvedOptions().locale

/**
 * Guesses the default iana timezone e.g. 'Australia/Sydney'. (Alternative: Intl.DateTimeFormat().resolvedOptions().timeZone // Australia/Sydney)
 */
export const getDefaultTimezone = () => moment.tz.guess()

/**
 * Guess the default currency, based on the country retuned by the timezone. e.g. { code: 'AUD', digits: 2 }
 */
export const getDefaultCurrency = () => {
    const [country] = getDefaultTimezone().split('/')
    const currencies = cc.country(country.toLowerCase())
    let currency = 'AUD' // default fallback
    if (currencies && currencies.length > 0) currency = currencies[0].code
    return currency
}
