
import debounce from 'debounce'
import React, { useState } from "react"
import { getDefaultCurrency, getDefaultLanguage, getDefaultTimezone } from '../components/Locale'
import firebase from '../config/config'
import API from '../utils/API'
import { put } from '../utils/JsonPath'
import _debounce from 'debounce'

const isPopulatedString = s => typeof s === 'string' && s.trim().length > 0
const isUndef = o => typeof o === 'undefined' || o === null
const merge = (a, b) => {
    Object.keys(b).forEach(key => { if (!isPopulatedString(a[key])) a[key] = b[key] })
    return a
}

const buildDefaultStore = () => ({
    records: {},
    profile: {
        uid: '',
        displayName: '',
        photoURL: '',
        email: '',
        emailVerified: false,
        phoneNumber: '',
        phoneNumberVerified: false,
        language: getDefaultLanguage(),
        timezone: getDefaultTimezone(),
        currency: getDefaultCurrency(),
        receiveWeeklyUpdates: true,
        receiveFriendUpdates: false,
    },
    isLoggedIn: null,
    loadingStatus: null,
})

const defaultState = {
    setStore: () => { },
    putRecord: () => { },
    deleteRecord: () => { },
    setStatus: () => { },
    store: buildDefaultStore(),
    records: {}
}
export const GlobalStoreContext = React.createContext(defaultState)

export const GlobalStoreProvider = (props) => {

    const [state, setState] = useState(() => {

        // deprecated, use effects instead to persist...
        const persistStore = (store) => {
            if (isPopulatedString(store.profile.uid)) {
                console.debug('updating store...', store)
                // localStorage.setItem('subzone_store', JSON.stringify(store))
                API.setStore(store.profile.uid, store)
            }
        }

        const putStore = (ps, jsonPath, value) => {
            if (put(ps.store, jsonPath, value)) {
                persistStore(ps.store)
                return { ...ps }
            } else {
                return ps
            }
        }

        // const clearStore = (ps, jsonPath) => {
        //     clear(ps.store, jsonPath)
        //     persistStore(ps.store)
        //     return { ...ps }
        // }

        return {

            // set entire store
            setStore: (currentUser, newStore) => {
                setState(ps => {
                    if (isUndef(currentUser)) {
                        // console.debug('user is not logged in and accessing auth page... send them to login page.')
                        ps.store.isLoggedIn = false
                        ps.store.loadingStatus = null // "User is not logged in, redirecting to login..."
                        return { ...ps }
                    } else {
                        // ensure store is properly up to date (i.e. handle inflight storage)
                        if (isUndef(newStore.records)) newStore.records = {}
                        if (isUndef(newStore.profile)) newStore.profile = {}
                        if (isUndef(newStore.profile.language)) newStore.profile.language = getDefaultLanguage()
                        if (isUndef(newStore.profile.timezone)) newStore.profile.timezone = getDefaultTimezone()
                        if (isUndef(newStore.profile.currency)) newStore.profile.currency = getDefaultCurrency()
                        if (isUndef(newStore.loadingStatus)) newStore.loadingStatus = null

                        console.debug('merging ID provider "user" details into profile......')
                        const { uid, displayName, photoURL, email, emailVerified, phoneNumber, phoneNumberVerified } = currentUser
                        merge(newStore.profile, { uid, displayName, email, phoneNumber })
                        newStore.profile.photoURL = photoURL
                        if (newStore.profile.emailVerified !== true && emailVerified === true) newStore.profile.emailVerified = true
                        if (newStore.profile.phoneNumberVerified !== true && phoneNumberVerified === true) newStore.profile.phoneNumberVerified = true
                        newStore.isLoggedIn = true
                        ps.store = newStore
                        return { ...ps, records: newStore.records }
                    }
                })
            },

            // accessor methods
            putRecord: record => {
                console.debug('putRecord', record)
                setState(ps => ({ ...ps, records: { ...ps.records, [record.id]: record } }))
            },
            deleteRecord: record => {
                console.debug('deleteRecord', record)
                setState(ps => {
                    delete ps.records[record.id]
                    return { ...ps, records: { ...ps.records } }
                })
            },
            putProfile: debounce(profile => {
                setState(ps => putStore(ps, 'profile', profile))
                return new Promise((resolve) => setTimeout(resolve, 500))
            }, 1000),
            setLoadingStatus: status => setState(ps => putStore(ps, 'loadingStatus', status)),
            clearLoadingStatus: status => setState(ps => putStore(ps, 'loadingStatus', null)),

            // accessor (raw data)
            store: buildDefaultStore(),
            records: {},
        }
    })

    const { setStore, setLoadingStatus, clearLoadingStatus, store, records } = state

    // run on user change...
    const loadUserStore = (currentUser) => {
        console.debug('GlobalStateContext - user changed', { currentUser })
        if (isUndef(currentUser)) {
            // user is not logged in...
            setStore(currentUser, buildDefaultStore())
        } else {
            setLoadingStatus('Loading user details...')
            try {
                API.getStore(currentUser.uid)
                    .then(res => {
                        // user is logged in and has data...
                        setStore(currentUser, res.data)
                        clearLoadingStatus()
                    })
                    .catch(err => {
                        const { message, stack } = err
                        console.debug('could not get user data from backend', { error: message, stack })
                        // todo check for 404? or does user have flag that they're new? show error?
                        // user is logged in but doesn't have data (new user)...
                        setStore(currentUser, buildDefaultStore())
                        clearLoadingStatus()
                    })
            } catch (err) {
                console.error('Error occurred loading existing store.', err.message)
                clearLoadingStatus()
            }
        }
    }

    const setLoggedInUser = newUser => {
        if (isUndef(newUser)) {
            // setCurrentUser(null)
            loadUserStore(null)
        } else {
            const { uid, displayName, photoURL, email, emailVerified, phoneNumber, phoneNumberVerified } = newUser
            const parsedUser = { uid, displayName, photoURL, email, emailVerified, phoneNumber, phoneNumberVerified }
            // setCurrentUser(parsedUser)
            loadUserStore(parsedUser)
        }
    }

    const persistStoreV2 = _debounce(state => {
        // todo version objects?
        const { store, records } = state
        const newStore = { ...store, records }
        // todo preferably use logged in user on backend, rather than explicitly using uid...
        if (isPopulatedString(store.profile.uid)) {
            console.debug('updating backend store...', newStore)
            API.setStore(store.profile.uid, newStore)
        }
    }, 5000)

    // --- effects ---

    // listen for login / logout user...
    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            // listen for user login/logout events (checks onload)...
            setLoadingStatus("Checking user is logged in...")
            firebase.auth().onAuthStateChanged(newUser => {
                console.debug('Firebase.onAuthStateChanged', newUser)
                return setLoggedInUser(newUser)
            })
        }
    }, [])

    React.useEffect(() => {
        persistStoreV2(state)
    }, [store, records])

    // useInterval(() => {
    //     getStatus().then(res => setStatus(res.data.statusMessage))
    // }, 5000)

    return (
        <GlobalStoreContext.Provider value={state}>
            {props.children}
        </GlobalStoreContext.Provider>
    )
}

export default GlobalStoreContext